const handlesLogin = {
    auth: async (email, password) => {
        $("#btnEnter").attr("disabled", "disabled").html(`<i class="fas fa-circle-notch fa-spin"></i>`);

        try {
            let data = { email, password };

            let responseAuth = await http.post(`login/auth`, data);

            if (!responseAuth.data.success) {
                return Swal.fire({
                    icon: "warning",
                    title: "Atenção",
                    html: responseAuth.data.message,
                });
            }

            location.href = $("head").data("root") + "/dashboard";
        } catch (error) {
            catchDefault(error);
        } finally {
            $("#btnEnter").removeAttr("disabled").html(`Entrar`);
        }
    }
};

$("#formLogin").validate({
    rules: {
        email: { required: true, email: true },
        password: { required: true }
    },
    messages: {
        email: { required: "Campo obrigatório", email: "E-mail inválido" },
        password: { required: "Campo obrigatório" }
    },
    errorClass: 'is-invalid',
    validClass: "is-valid",
    errorElement: "small",
    errorPlacement: function (error, element) {
        $(element)
            .closest("div")
            .find(".invalid-feedback")
            .append(error);
    },
    submitHandler: function () {
        handlesLogin.auth($("#email").val(), $("#password").val());
    }
});